<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
  import {
    use
  } from "echarts/core"
  import {
    CanvasRenderer
  } from "echarts/renderers"
  import {
    BarChart,
    LineChart
  } from "echarts/charts"

  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent
  } from "echarts/components"

  import { getCouponData } from "@/api/api.js"
  let moment = require("moment")

  use([
    CanvasRenderer,
    BarChart,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent
  ])
  export default {
    components: {},
    data() {
      return {
        option: {
          tooltip: {},
          legend: {
            data: ["销售额(万)"],
            right: 0,
            textStyle: {
              color: "#a3fffe",
              fontSize: "14"
            }
          },
          xAxis: [{
            type: "category",
            data: ["上月", "本月"],
            axisPointer: {
              type: "shadow",
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#a3fffe",
              },
            },
            axisTick: {
              show: false
            }
          }],
          yAxis: [{
              type: "value",
              axisLabel: {
                formatter: "{value}",
              },
              axisLine: {
                lineStyle: {
                  color: "#a3fffe",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#114556",
                  type: "dotted"
                }
              }
            },
            {
              type: "value",
              show: false,
              min: 0,
              max: 200,
              interval: 40
            }
          ],
          series: [
            {
              name: "销售额(万)",
              type: "bar",
              data: [],
              itemStyle: {
                normal: {
                  label: {
                    show: true
                  }
                },
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                      offset: 0,
                      color: "#47adf5"
                    },
                    {
                      offset: 1,
                      color: "#143a53"
                    },
                  ],
                  globalCoord: false
                }
              }
            }
          ]
        }
      }
    },
    methods: {
      dateFormat(fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }
        return fmt;
      },
      getData() {
        const params = {
          "reqCodes": [31111000],
          "params": { }
        }
        getCouponData(params).then((res) => {
          const data = res.data.data["31111000"]
          if(data){
            const month = moment().month()
            this.option.series[0].data = [data[month + 1]["金额"], data[month]["金额"]]
            if(this.option.series[0].data.every(item => item === 0)) {
              this.option.series[0].data = [105, 12]
            }
          }
        })
      }
    },
    mounted() {
      this.getData()
      this.timer = setInterval(this.getData, 6000);
    },
    beforeDestroy() {
      clearInterval(this.timer)
    },
  };
</script>

<style scoped>
</style>
