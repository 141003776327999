<template>
  <v-chart class="chart" :option="option" />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { getCouponData } from "@/api/api.js";
let moment = require("moment");

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
export default {
  props: ["data"],
  data() {
    return {
      timer: 0,
      option: {
        legend: {
          type: "plain",
          orient: "vertical",
          right: "5%",
          top: "5%",
          textStyle: {
            color: "#a3fffe",
            fontSize: "12",
          },
          itemHeight: 12,
          itemWidth: 12,
          itemGap: 10,
        },
        tooltip: {
          trigger: "item",
        },
        series: {
          name: "近期优惠券情况",
          type: "pie",
          radius: [40, 70],
          center: ["35%", "50%"],
          roseType: "area",
          data: this.newData,
          left: "0%",
          itemStyle: {
            normal: {
              label: {
                show: true,
                formatter: "{c}",
              },
            },
          },
        },
      },
    };
  },
  methods: {
    getData() {
      const month = moment().format("YYYY-MM")
      const params = {
        "reqCodes": [21251000],
        "params": { start_month: month, end_month: month }
      }
      return getCouponData(params).then((res) => {
        const data = res.data.data["21251000"];
        if(data){
        this.option.series.data = [
          {
            name: "近期优惠券领取数量",
            value: data["券领取数量"],
          },
          {
            name: "近期优惠券使用数量",
            value: data["券核销数量"],
          },
        ];
        }
      })
    },
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.getData, 6000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
};
</script>

<style scoped>
</style>
