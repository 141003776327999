<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import {
  use
} from "echarts/core";
import {
  CanvasRenderer
} from "echarts/renderers";
import {
  PieChart
} from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";

import { getIndestryInfo } from "@/api/api.js";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
var data = [{
    value: 70,
    name: "超市"
  },
  {
    value: 58,
    name: "珠宝"
  },
  {
    value: 12,
    name: "小吃"
  },
  {
    value: 40,
    name: "化妆品"
  },
  {
    value: 28,
    name: "电子产品"
  }
]
export default {
  components: {},
  data() {
    return {
      option: {
        legend: {
          type: "plain",
          orient: "vertical",
          right: "10%",
          top: "20%",
          textStyle: {
            color: "#a3fffe",
            fontSize: "16",
          },
          itemHeight: 12,
          itemWidth: 12,
          itemGap: 20,
        },
        tooltip: {
          trigger: "item",
        },
        series: [{
          name: "商铺行业占比",
          type: "pie",
          radius: [40, 70],
          center: ["30%", "50%"],
          roseType: "area",

          data: data,
          
          itemStyle: {
            normal: {
              label: {
                show: true,
                formatter: '{c}%'
              }
            }
          },
          left: "0%",
        }, ],
      },
    };
  },
  methods: {
    getData() {
      getIndestryInfo().then((res) => {
        this.option.series[0].data = res.data.data.map((o) => {
          return {name: o.type_name, value: o.percent}
        }).slice(0, 8)
      });
    },
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.getData, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
};
</script>

<style scoped></style>
