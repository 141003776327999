<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use, graphic } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import { getTrafficTrend } from "@/api/api.js";
const moment = require('moment')

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
export default {
  components: {},
  props: ["data"],
  data() {
    return {
      timer: 0,
      option: {
        color: ["#b59424", "#1a9f90"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },

        grid: {
          top: "10%",
          left: "3%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#557562",
              },
            },
            axisLabel: {
              show: true,
              color: "#a3fffe",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              lineStyle: {
                color: "#114556",
              },
            },

            axisLabel: {
              show: true,
              color: "#a3fffe",
              fontSize: 16,
            },
          },
        ],
        series: [
          {
            name: "人次",
            type: "line",
            emphasis: {
              focus: "series",
            },
            data: [0, 0, 0, 0, 0, 0, 0],
            areaStyle: {
              opacity: 0.8,
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(157, 227, 246, 0.9)",
                },
                {
                  offset: 1,
                  color: "rgba(100, 161, 246, 0.6)",
                },
              ]),
            },
            lineStyle: {
              color: "#264e53",
              width: 0.5,
            },
            itemStyle: {
              opacity: 0,
              normal: {
                label: {
                  show: true
                }
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    getData() {
      let dates = [],
          data = [],
          promises = []
      for(let index = 7; index > 0; index--){
        const date = moment().subtract(index, 'days')
        dates.push(date.format("MM-DD"))
        promises.push(getTrafficTrend(date.format("YYYY-MM-DD 22:00:00"), date.format("YYYY-MM-DD 22:00:00")))
      }
      Promise.all(promises).then(resps=>{
        resps.forEach((res) => {
          data.push(res.data.data[0].sum_flow)
        })
      })
      this.option.xAxis[0].data = dates
      this.option.series[0].data = data
    },
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.getData, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
};
</script>

<style scoped>
.chart {
  min-height: 250px;
}
</style>