<template>
  <section class="store-info">
    <div class="store-list">
      <table>
        <thead>
          <tr>
            <th>
              <div class="bg">商铺</div>
            </th>
            <th>
              <div class="bg">核销量</div>
            </th>
            <th>
              <div class="bg">占比</div>
            </th>
          </tr>
        </thead>
        <tbody class="store-list-wrap" :class="{ 'animate-up': animateUp }">
          <tr v-for="(person, i) in persons" :key="i">
            <td>
              <div class="bg2">
                {{ person.store_name }}
              </div>
            </td>
            <td>
              <div class="bg2">{{ person.verify_number }}</div>
            </td>
            <td>
              <div class="bg2">
                {{ person.verify_rate }}%
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import { getCouponData } from "@/api/api.js";
let moment = require("moment");

export default {
  data() {
    return {
      animateUp: false,
      timer: null,
      persons: [
        { name: "咸享酒店", address: "绍兴市越城区鲁迅中路179号", num: "-" },
        { name: "鲁迅故里", address: "绍兴市越城区鲁迅中路241号", num: "-" },
        { name: "三味书屋", address: "绍兴市越城区鲁迅中路241号", num: "-" },
        { name: "鲁迅纪念馆", address: "绍兴市越城区鲁迅中路235号", num: "-" },
        { name: "百草园", address: "绍兴市越城区鲁迅中路187号", num: "-" },
      ],
    };
  },
  methods: {
    scrollAnimate() {
      this.animateUp = true;
      setTimeout(() => {
        this.persons.push(this.persons[0]);
        this.persons.shift();
        this.animateUp = false;
      }, 500);
    },
    getData(){
      const month = moment().format("YYYY-MM")
      const params = {
        "reqCodes": [31251003],
        "params": { start_month: month, end_month: month }
      }
      getCouponData(params).then((res) => {
        const data = res.data.data["31251003"];
        if(data){
          this.persons = data.map((item) => {
            return {
              store_name: item["商铺"],
              verify_number: item["核销量"],
              verify_rate: (item["占比"] * 100).toFixed(2)
            }
          })
        }
      })
    }
  },
  destroyed() {
    clearInterval(this.timer);
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.scrollAnimate, 3000);
  },
};
</script>
<style scoped>
.store-info table {width:100%;color:#fff;margin-top:18px;}
.store-info table .bg {
  background: #14282e;
  padding: 8px;
  margin-bottom: 3px;
}

.store-info table .bg2 {
  background: rgba(12, 28, 34, 0.7);
  padding: 8px;
  margin-bottom: 3px;
}


.store-info table td:first-child .bg2,
.store-info table th:first-child .bg {
  border-radius: 10px 0 0 10px;
}

.store-info table td:last-child .bg2,
.store-info table th:last-child .bg {
  border-radius: 0 10px 10px 0;height: 36px;
}

.store-info table td,
.store-info table thead th {
  padding: 0;text-align:center
}


.store-list {
  height: 270px;
  overflow: hidden;
  font-size: 14px;
}
.store-list thead {
  position: relative;
  z-index: 1;
}
.store-list-wrap {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.store-list .animate-up {
  transition: all 0.5s ease-in-out;
  transform: translateY(-40px);
}
</style>
